export const menuData = {
  tapas: {
    title_fr: "TAPAS",
    title_en: "TAPAS",
    description_fr: "A partager",
    description_en: "To share",
    content: [
      {
        name_fr: "PALLINA",
        name_en: "PALLINA",
        description_fr: "Chaussons fourrés sauce tomate et mozzarella",
        description_en: "Turnovers filled with tomato and mozzarella sauce",
        subContent: [
          {
            quantity: null,
            price: 9.5,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CIABATTA",
        name_en: "CIABATTA",
        description_fr: "Pain à l'ail, tomates séchées, bresaola et mozzarella",
        description_en:
          "Garlic bread with sun-dried tomatoes, bresaola and mozzarella",
        subContent: [
          {
            quantity: null,
            price: 10.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "ARANCINI OJS",
      //   name_en: "ARANCINI OJS",
      //   description_fr: "Boule de riz frit et sa sauce tomate",
      //   description_en: "Fried rice ball with tomato sauce",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 6
      //     }
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "OEUF MIMOSA A L'ITALIENNE",
      //   name_en: "ITALIAN-STYLE MIMOSA EGG",
      //   description_fr: "Oeuf façon mimosa mayonnaise au pesto",
      //   description_en: "Mimosa-style egg with pesto mayonnaise",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 6,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "CALAMARI ALLA ROMANA",
      //   name_en: "CALAMARI ALLA ROMANA",
      //   description_fr: "Calamar à la romaine",
      //   description_en: "Roman-style squid",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 6.5,
      //     },
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "O'PTIT RITAL",
        name_en: "O'PTIT RITAL",
        description_fr: "Mix tapas - Charcuterie, fromage, légumes",
        description_en: "Tapas mix - Cold cuts, cheese, vegetables",
        person_fr: "personne",
        person_en: "person",
        subContent: [
          {
            quantity: null,
            price: 8,
            perPerson: true,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "ITALIANA",
      //   name_en: "ITALIANA",
      //   description_fr: "Charcuterie mixte italienne",
      //   description_en: "Italian mixed cold cuts",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 14,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "CROC A LA TRUFFE",
      //   name_en: "TRUFFLE CROC",
      //   description_fr: "Croque monsieur à la crème de truffe",
      //   description_en: "Croque monsieur with truffle cream",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "PATATAS RICOTTAS",
      //   name_en: "PATATAS RICOTTAS",
      //   description_fr: "Pomme de terre grenaille et sa crème de ricotta",
      //   description_en: "Grilled potato with ricotta cream",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 8,
      //     },
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "CROSTINI FLORENTINE",
      //   name_en: "CROSTINI FLORENTINE",
      //   description_fr:
      //     "Boule de pizza en friture, crème de pécorino et son jambon San Daniele",
      //   description_en:
      //     "Fried pizza ball with pecorino cream and San Daniele ham",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
    ],
  },
  antipasti: {
    title_fr: "ANTIPASTI",
    title_en: "ANTIPASTI",
    description_fr: "Les entrées",
    description_en: "The starters",
    content: [
      {
        name_fr: "BURRATINA",
        name_en: "BURRATINA",
        description_fr: "Burratina, mesclun, tomates cerises, oignons rouges",
        description_en: "Burratina, mesclun, cherry tomatoes, red onions",
        subContent: [
          {
            quantity: null,
            price: 10.5,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "OEUF COCOTTE A LA TRUFFE",
        name_en: "EGG CASSEROLE WITH TRUFFLE",
        description_fr:
          "Oeuf cocotte, crème de truffe, champignons de Paris et lard fumé",
        description_en:
          "Egg casserole, truffle cream, Paris mushrooms and smoked bacon",
        subContent: [
          {
            quantity: null,
            price: 10.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "TARTARE DE SAUMON AVOCAT",
        name_en: "SALMON AND AVOCADO TARTAR",
        description_fr: "Saumon mariné et avocat",
        description_en: "Marinated salmon and avocado",
        subContent: [
          {
            quantity: null,
            price: 12.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "LA CAPRESE",
      //   name_en: "THE CAPRESE",
      //   description_fr: "Tomates, mozzarella di bufala, basilic et basta",
      //   description_en: "Tomatoes, mozzarella di bufala, basil and basta",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 12.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "L'ÉPINARD FAÇON OJS",
      //   name_en: "SPINACH OJS",
      //   description_fr: "Salade d'épinard, sauce gorgonzola, pignons de pin et jambon San Daniel",
      //   description_en: "Spinach salad with gorgonzola sauce, pine nuts and San Daniel ham",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9
      //     }
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false
      // }
      // {
      //   name_fr: "MI-CUIT DE SAUMON FLAMBÉ",
      //   name_en: "HALF-COOKED FLAMED SALMON",
      //   description_fr: "Saumon gravlax zeste de citron vert flambé à table",
      //   description_en: "Lime zest gravlax salmon flamed at the table",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 12.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "VELOUTÉ DE CHAMPIGNONS DE PARIS",
      //   name_en: "CREAM OF PARIS MUSHROOMS SOUP",
      //   description_fr: "",
      //   description_en: "",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9.5,
      //     },
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "MOULES À L'ITALIENNE",
      //   name_en: "ITALIAN STYLE MUSSELS",
      //   description_fr: "Moules marinées à l'ail et sauce tomate",
      //   description_en: "Mussels marinated in garlic and tomato sauce",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 7.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
    ],
  },
  pasta: {
    title_fr: "LA PASTA",
    title_en: "LA PASTA",
    description_fr:
      "Nos recettes de pâtes sauront ravir vos papilles par leur originalité et leurs nuances méditerranéennes",
    description_en:
      "Our pasta recipes will delight your taste buds with their originality and their Mediterranean nuances",
    content: [
      {
        name_fr: "TRUFFINA",
        name_en: "TRUFFINA",
        description_fr: "Raviolis 4 fromages à la crème de truffe",
        description_en: "4 cheese ravioli with truffle cream",
        subContent: [
          {
            quantity: null,
            price: 19.5,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CARBONARI",
        name_en: "CARBONARI",
        description_fr: "Carbonara façon O' Jardin Secret...",
        description_en: "Carbonara way O' Jardin Secret...",
        subContent: [
          {
            quantity: null,
            price: 19.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "GNOCCHI 'POMZOLA'",
      //   name_en: "GNOCCHI 'POMZOLA'",
      //   description_fr:
      //     "Gnocchis de pomme de terre accompagnées de morceaux de pommes caramélisées, émincé de bresaola, sauce gorgonzola noix et miel",
      //   description_en:
      //     "Potato gnocchi with caramelized apple pieces, sliced bresaola with gorgonzola nut and honey sauce",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 19.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "GNOCCHI 'SALMONE'",
      //   name_en: "GNOCCHI 'SALMONE'",
      //   description_fr:
      //     "Gnocchi de pommes de terre accompagnées de saumon, épinard et fromage de chèvre",
      //   description_en:
      //     "Potato gnocchi with salmon, spinach and goat cheese",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 18.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "GAMBERI CHORIZO",
      //   name_en: "GAMBERI CHORIZO",
      //   description_fr:
      //     "Pasta à la crème de chorizo et crevette",
      //   description_en:
      //     "Pasta with chorizo cream and shrimp",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 20.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      {
        name_fr: "LASAGNE",
        name_en: "LASAGNA",
        description_fr:
          "Lasagne classique, du boeuf, du fromage, de la béchamel, le tout parsemé d'amour",
        description_en:
          "Classic lasagne, beef, cheese, béchamel sauce, sprinkled with love",
        subContent: [
          {
            quantity: null,
            price: 16,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "MELANZANA",
      //   name_en: "MELANZANA",
      //   description_fr:
      //     "Pasta au caviar d’aubergine, tomate cerise et parmesan",
      //   description_en:
      //     "Pasta with eggplant caviar, cherry tomatoes and Parmesan cheese",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 14.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "PAELLA ITALIANA",
      //   name_en: "PAELLA ITALIANA",
      //   description_fr: "Pasta au curry jaune poulet, crevette et poulpe",
      //   description_en: "Yellow curry pasta with chicken, shrimp and octopus",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 20.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "PESTO PISTACHIO",
        name_en: "PESTO PISTACHIO",
        description_fr:
          "Pasta au pesto avec pistaches, stracciatella et tomates cerises (Pour les carnivores, demandez votre supplément +3€)",
        description_en:
          "Pesto pasta with stracciatella pistachios and cherry tomatoes (For the carnivore ask for your supplement +3€)",
        subContent: [
          {
            quantity: null,
            price: 15.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "PASTA DELL'AMORE",
      //   name_en: "PASTA DELL'AMORE",
      //   description_fr:
      //     "Pour les fans de la belle et le clochard, la recette du film avec boulettes de boeuf et la sauce tomate de la mamma",
      //   description_en:
      //     "For the fans of beauty and the tramp, the recipe of the movie with beef balls and the tomato sauce of the mamma",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 20.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "RIGATONI PESTO ROSSO",
      //   name_en: "RIGATONI PESTO ROSSO",
      //   description_fr:
      //     "Un pesto rouge secret et de la ricotta (la recette est sur instagram 😉)",
      //   description_en:
      //     "A secret red pesto and ricotta cheese (recipe is on instagram 😉)",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 20.5,
      //     },
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "CACIO E PEPE FAÇON OJS",
      //   name_en: "CACIO E PEPE WAY OJS",
      //   description_fr: "Flambé dans sa meule à table",
      //   description_en: "Flamed in its millstone at the table",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 22.5,
      //     },
      //   ],
      //   vegetarian: true,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "LA LASAGNE SALMONE",
      //   name_en: "SALMON LASAGNA",
      //   description_fr:
      //     "Lasagne revisitée au saumon, chèvre et pesto de roquette",
      //   description_en:
      //     "Lasagne revisited with salmon, goat cheese and arugula pesto",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 17.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "MACCHERONI DI BUFFALA",
      //   name_en: "MACCHERONI DI BUFFALA",
      //   description_fr:
      //     "Maccheroni, pesto, pistache et mortadelle, billes de buffala",
      //   description_en:
      //     "Maccheroni, pesto, pistachio and mortadella, buffalo balls",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 20.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "PASTA AL FUNGHI",
      //   name_en: "PASTA AL FUNGHI",
      //   description_fr:
      //     "Parppadelle à la crème marinée aux Cèpes et champignons de Paris",
      //   description_en:
      //     "Parppadelle with cream marinated in porcini and button mushrooms",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 20.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
    ],
  },
  insalata: {
    title_fr: "INSALATA",
    title_en: "INSALATA",
    description_fr: "Les salades",
    description_en: "Salads",
    content: [
      {
        name_fr: "LA CESARO",
        name_en: "LA CESARO",
        description_fr:
          "Mesclun, poulet mariné, jambon San Daniel, gressini et sauce César",
        description_en:
          "Mesclun, marinated chicken, San Daniel ham, gressini and Caesar sauce",
        subContent: [
          {
            quantity: null,
            price: 17.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PRIMAVERA",
        name_en: "PRIMAVERA",
        description_fr: "Mesclun, légume du moment et burratina",
        description_en: "Mesclun, vegetable of the moment and burratina",
        subContent: [
          {
            quantity: null,
            price: 16.5,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA PROSCIUTO CRUDO",
        name_en: "LA PROSCIUTO CRUDO",
        description_fr:
          "Jambon San Daniel, mesclun, burratina, tomates cerises, tomates séchées et parmesan",
        description_en:
          "San Daniel ham, mesclun, burratina, cherry tomatoes, dried tomatoes and parmesan",
        subContent: [
          {
            quantity: null,
            price: 17.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "SALADE OCEANE",
        name_en: "OCEAN SALAD",
        description_fr: "Saumon, salade, burratina, tomates cerises et oignons",
        description_en: "Salmon, salad, burratina, cherry tomatoes and onions",
        subContent: [
          {
            quantity: null,
            price: 17.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  specialita: {
    title_fr: "SPECIALITA DELLA CASA",
    title_en: "SPECIALITA DELLA CASA",
    description_fr: "Les spécialités de la Maison",
    description_en: "House specialties",
    content: [
      {
        name_fr: "PÊCHE DU MARCHÉ",
        name_en: "CATCH OF THE DAY",
        description_fr:
          "Selon arrivage",
        description_en:
          "Subject to availability",
        subContent: [
          {
            other_fr: "Selon arrivage",
            other_en: "Subject to availability"
          }
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PIÈCE DU BOUCHER",
        name_en: "BUTCHER'S CUT",
        description_fr:
          "Selon arrivage",
        description_en:
          "Subject to availability",
        subContent: [
          {
            other_fr: "Selon arrivage",
            other_en: "Subject to availability"
          }
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CARPACCIO",
        name_en: "CARPACCIO",
        description_fr:
          "Tranche de boeuf, parmesan, courgette, aubergine, burrata, tomates, salade, oignons rouges",
        description_en:
          "Sliced beef, parmesan, zucchini, eggplant, burrata, tomatoes, lettuce, red onions",
        subContent: [
          {
            quantity: null,
            price: 18.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "MILANAISE REVISITÉE",
      //   name_en: "MILANAISE REVISITED",
      //   description_fr:
      //     "Milanaise de poulet revisité par le Chef",
      //   description_en:
      //     "Chicken Milanaise revisited by the Chef",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 22.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "BURGER A L'ITALIENNE",
      //   name_en: "ITALIAN STYLE BURGER",
      //   description_fr:
      //     "Boulette de boeuf, tomate, salade, pesto, mozzarella, sauce gorgonzola, buns pizza servit avec des pommes de terre grenaille",
      //   description_en:
      //     "Beef meatball, tomato, salad, pesto, mozzarella, gorgonzola sauce, pizza buns served with grilled potatoes",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 22,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "FILET DE SAUMON AL FORNO",
      //   name_en: "SALMON FILET AL FORNO",
      //   description_fr:
      //     "Filet de saumon cuit au four et ses petits légumes à l'italienne",
      //   description_en: "Baked salmon filet with Italian style vegetables",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 19.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      // {
      //   name_fr: "POLPO AL FORNO",
      //   name_en: "POLPO AL FORNO",
      //   description_fr:
      //     "Tentacule de poulpe cuit au four et ses petits légumes à l’italienne",
      //   description_en: "Baked octopus tentacle with Italian-style vegetables",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 29,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "TARTARE ITALIANA",
        name_en: "ITALIAN-STYLE BEEF TARTARE",
        description_fr:
          "Tartare de boeuf au pesto, parmesan et crème de balsamique",
        description_en: "Beef tartare with pesto, parmesan and balsamic cream",
        subContent: [
          {
            quantity: null,
            price: 22,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "RISOTTO ROSA",
      //   name_en: "RISOTTO ROSA",
      //   description_fr: "Risotto crème de betterave, gorgonzola",
      //   description_en: "Risotto with beet cream and gorgonzola cheese",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 19,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "SALTIMBOCCA AL POLLO",
        name_en: "SALTIMBOCCA AL POLLO",
        description_fr: "Escalope de poulet gratinée a la mozzarella et au jambon San Daniele",
        description_en: "Chicken cutlet gratin with mozzarella and San Daniele ham",
        subContent: [
          {
            quantity: null,
            price: 22.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "OSSO BUCCO",
      //   name_en: "OSSO BUCCO",
      //   description_fr:
      //     "Jarret de veau avec son os à moelle 350g et pâtes mafaldine à la tomate",
      //   description_en:
      //     "Veal shank with marrow bone 350g and mafaldine pasta with tomato",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 24.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "LE CORDON BLEU ITALIEN",
      //   name_en: "THE ITALIAN CORDON BLEU",
      //   description_fr:
      //     "Filet de poulet roulé en chapelure, tomates et mozzarella, fior de latte et San Daniele et gorgonzola",
      //   description_en:
      //     "Chicken fillet rolled in breadcrumbs, tomatoes and mozzarella, fior de latte and San Daniele and gorgonzola",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 22.5
      //     }
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false
      // },
      // {
      //   name_fr: "RISOTTO AL MARE",
      //   name_en: "RISOTTO AL MARE",
      //   description_fr: "Selon les arrivages du jour...",
      //   description_en: "According to the arrivals of the day...",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 19.5,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
    ],
  },
  pizze: {
    title_fr: "PIZZE",
    title_en: "PIZZE",
    description_fr: "Pizza",
    description_en: "Pizza",
    content: [
      {
        name_fr: "LA MARGHERITA",
        name_en: "MARGHERITA",
        description_fr: "Tomates, mozzarella, origan",
        description_en: "Tomatoes, mozzarella, oregano",
        subContent: [
          {
            quantity: "15 cm",
            price: 8.5,
          },
          {
            quantity: "30 cm",
            price: 11,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA REGINA",
        name_en: "REGINA",
        description_fr: "Margherita, jambon, champignons, olives",
        description_en: "Margherita, ham, mushrooms, olives",
        subContent: [
          {
            quantity: "15 cm",
            price: 13.5,
          },
          {
            quantity: "30 cm",
            price: 15,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA 4 FROMAGGI",
        name_en: "4 FROMAGGI",
        description_fr:
          "Margherita, chèvre, gorgonzola, parmesan, pecorino sardo",
        description_en:
          "Margherita, goat cheese, gorgonzola, parmesan, pecorino sardo",
        subContent: [
          {
            quantity: "15 cm",
            price: 13.5,
          },
          {
            quantity: "30 cm",
            price: 15,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA CALZONE",
        name_en: "CALZONE",
        description_fr: "Pizza soufflée sur base de margherita, jambon, oeuf",
        description_en: "Pizza spread with margherita, ham and egg",
        subContent: [
          {
            quantity: "30 cm",
            price: 15,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA PEPERONI",
        name_en: "PEPERONI",
        description_fr:
          "Margherita, pecorino sardo, chorizo, olives, poivrons, roquette, parmesan",
        description_en:
          "Margherita, pecorino sardo, chorizo, olives, peppers, arugula, parmesan",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 16.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA POLLO",
        name_en: "POLLO",
        description_fr: "Margherita, poulet (hallal), chèvre, miel",
        description_en: "Margherita, chicken (hallal), goat, honey",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 16.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA SAN DANIELE",
        name_en: "SAN DANIELE",
        description_fr: "Margherita, jambon cru, roquette, parmesan",
        description_en: "Margherita, raw ham, arugula, parmesan",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 16.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA VEGETARIANA",
        name_en: "VEGETARIANA",
        description_fr: "Margherita, légumes de saison, burratina",
        description_en: "Margherita, seasonal vegetables, burratina",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 18.5,
          },
        ],
        vegetarian: true,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA BRESAOLA",
        name_en: "BRESAOLA",
        description_fr: "Margherita, bresaola, roquette, burratina, parmesan",
        description_en: "Margherita, bresaola, arugula, burratina, parmesan",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 18.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA MARINARA",
        name_en: "MARINARA",
        description_fr: "Margherita, burratina, saumon, avocat, roquette",
        description_en: "Margherita, burratina, salmon, avocado, arugula",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 19.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA GORGONZOLA",
        name_en: "GORGONZOLA",
        description_fr:
          "Crème de ciboulette, gorgonzola, jambon San Daniele, noix",
        description_en: "Chive cream, gorgonzola, San Daniele ham, nuts",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 17.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA TARTUFO",
        name_en: "TARTUFO",
        description_fr:
          "Crème de truffe, parmesan, jambon San Daniel et tomates cerises",
        description_en:
          "Truffle cream, parmesan, San Daniel ham and cherry tomatoes",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 19.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA SEGRETO",
        name_en: "SEGRETO",
        description_fr:
          "Laissez-vous faire par notre chef ou confiez nous votre recette secrète",
        description_en:
          "Let our chef do it for you or tell us your secret recipe",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 17.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "L'AVOCADO",
        name_en: "AVOCADO",
        description_fr:
          "Crème fraiche, jambon San Daniel, poulet, avocat, oignons rouges, tomates cerises, mozzarella",
        description_en:
          "Fresh cream, San Daniel ham, chicken, avocado, red onions, cherry tomatoes, mozzarella",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 18.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "LA PISTACCHIO",
        name_en: "PISTACCHIO",
        description_fr:
          "Margherita, pistache, jambon blanc, tomates cerises, stracciatella, roquettes",
        description_en:
          "Margherita, pistachio, ham, cherry tomatoes, stracciatella, arugula",
        subContent: [
          {
            quantity: "15 cm",
            price: 14.5,
          },
          {
            quantity: "30 cm",
            price: 17.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "Supplément garniture pizza",
        name_en: "Pizza topping supplement",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 2.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "Supplément burratina",
        name_en: "Burratina supplement",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 4,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
  dessert: {
    title_fr: "DOUCEURS",
    title_en: "DESERTS",
    description_fr: "Dolce",
    description_en: "Dolce",
    content: [
      {
        name_fr: "TIRAMISU CLASSICO",
        name_en: "CLASSICO TIRAMISU",
        description_fr: "Tiramisu traditionnel au café",
        description_en: "Traditional coffee tiramisu",
        subContent: [
          {
            quantity: null,
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "TIRAMISU DU MOMENT",
      //   name_en: "INSTANT TIRAMISU",
      //   description_fr: "",
      //   description_en: "",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "FONDANT AU CHOCOLAT",
        name_en: "CHOCOLATE FONDANT",
        description_fr: "Fondant chocolat tiède et crème anglaise",
        description_en: "Warm chocolate fondant and custard",
        subContent: [
          {
            quantity: null,
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "CHEESECAKE À L'ITALIENNE",
      //   name_en: "ITALIAN CHEESECAKE",
      //   description_fr: "Ricotta, mozzarella di buffala, caramel et spéculos",
      //   description_en: "Ricotta, buffalo mozzarella, caramel et spéculos",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 9,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "PANNA COTTA",
        name_en: "PANNA COTTA",
        description_fr: "Coulis de fruits rouges",
        description_en: "Red fruit coulis",
        subContent: [
          {
            quantity: null,
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "PAIN PERDU",
        name_en: "PAIN PERDU",
        description_fr: "",
        description_en: "",
        subContent: [
          {
            quantity: null,
            price: 9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CIABATTA NUTELLA",
        name_en: "CIABATTA NUTELLA",
        description_fr: "Pain pizza nutella",
        description_en: "Nutella pizza",
        subContent: [
          {
            quantity: null,
            price: 10.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      // {
      //   name_fr: "SALADE DE FRUITS",
      //   name_en: "FRUIT SALAD",
      //   description_fr: "",
      //   description_en: "",
      //   subContent: [
      //     {
      //       quantity: null,
      //       price: 8,
      //     },
      //   ],
      //   vegetarian: false,
      //   bio: false,
      //   spicy: false,
      // },
      {
        name_fr: "BOULE DE GLACE",
        name_en: "ICE BALL",
        description_fr:
          "Vanille, chocolat, framboise, citron, café, caramel, macadamia, pistache, mangue et noisette",
        description_en:
          "Vanilla, chocolate, raspberry, lemon, coffee, caramel, macadamia, pistachio, mango and hazelnut",
        subContent: [
          // {
          //   quantity: "1",
          //   price: 3.5,
          // },
          {
            quantity: "2",
            price: 6.5,
          },
          {
            quantity: "3",
            price: 8.5,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "CAFFE SEGRETO",
        name_en: "SEGRETO COFFEE",
        description_fr:
          "Le caffé secreto est un café gourmand composé d'un assortiment des desserts. Seul le Chef en connait le contenu à l'avance. Laissez-vous tenter.",
        description_en:
          "The segreto coffee is a gourmet coffee composed of an assortment of desserts. Only the Chef knows the contents in advance. Let yourself be tempted.",
        subContent: [
          {
            quantity: null,
            price: 9.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
      {
        name_fr: "TE SEGRETO",
        name_en: "SEGRETO TEA",
        description_fr:
          "Le té secreto est un thé gourmand composé d'un assortiment des desserts. Seul le Chef en connait le contenu à l'avance. Laissez-vous tenter.",
        description_en:
          "The Segreto Tea is a gourmet tea composed of an assortment of desserts. Only the Chef knows the contents in advance. Let yourself be tempted.",
        subContent: [
          {
            quantity: null,
            price: 10.9,
          },
        ],
        vegetarian: false,
        bio: false,
        spicy: false,
      },
    ],
  },
};
